import React, { useState, useEffect } from "react";
import "./Gln.css"; // Import stylów dla tabeli GLN
import ClipLoader from "react-spinners/ClipLoader"; // Import loadera
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

// Komponent GlnTable
const GlnTable = () => {
  const [data, setData] = useState(null); // Stan do przechowywania danych
  const [loading, setLoading] = useState(true); // Stan do zarządzania ładowaniem
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 50;

  // Pola filtrowania
  const [filterOdbiorca, setFilterOdbiorca] = useState("");
  const [filterGln, setFilterGln] = useState("");
  const [filterOpis, setFilterOpis] = useState("");
  const [filterNip, setFilterNip] = useState("");
  const handleRefresh = () => {
    fetchGLN(); // Wywołanie ponownego pobrania danych
  };
  // Funkcja do pobrania danych GLN
  const fetchGLN = async () => {
    try {
      setLoading(true); // Ustawienie stanu ładowania na true przed rozpoczęciem pobierania
      const response = await fetch(`/getGLN`);
      if (!response.ok) {
        throw new Error(`Błąd HTTP: ${response.status}`);
      }
      const result = await response.json();
      setData(result.USER); // Aktualizacja danych
      setLoading(false); // Ustawienie stanu ładowania na false po zakończeniu pobierania
    } catch (error) {
      console.error("Błąd podczas pobierania danych GLN:", error);
      setLoading(false); // Nawet w przypadku błędu wyłączamy stan ładowania
    }
  };

  // Pobieranie danych przy pierwszym renderze komponentu
  useEffect(() => {
    fetchGLN();
  }, []);

  // Obliczanie indeksów dla paginacji
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  if (loading) {
    return (
      <div className="loader-container">
        <h1>Trwa ładowanie danych GLN</h1>
        <ClipLoader color="black" loading={loading} size={50} />
      </div>
    );
  }

  // Zabezpieczenie, jeśli dane nie zawierają klucza RESULTSTAB
  if (!data || !data.RESULTSTAB || data.RESULTSTAB.length === 0) {
    return <p>Brak danych do wyświetlenia</p>;
  }

  // Filtrowanie danych
  const filteredRecords = data.RESULTSTAB.filter((record) => {
    return (
      record.ODBIORCA.toLowerCase().includes(filterOdbiorca.toLowerCase()) &&
      record.GLN.toLowerCase().includes(filterGln.toLowerCase()) &&
      record.OPIS.toLowerCase().includes(filterOpis.toLowerCase()) &&
      record.NIP.toLowerCase().includes(filterNip.toLowerCase())
    );
  });

  // Paginacja po filtrowaniu
  const currentRecords = filteredRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Funkcja do zmiany strony
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredRecords.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="gln-container">
      {/* Pola wyszukiwania */}
      <div className="filter-container">
        <input
          type="text"
          placeholder="Filtruj po Odbiorcy"
          value={filterOdbiorca}
          onChange={(e) => setFilterOdbiorca(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtruj po GLN"
          value={filterGln}
          onChange={(e) => setFilterGln(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtruj po Opisie"
          value={filterOpis}
          onChange={(e) => setFilterOpis(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtruj po NIP"
          value={filterNip}
          onChange={(e) => setFilterNip(e.target.value)}
        />
      </div>
      <button className="refresh-button" onClick={handleRefresh}>
        <FontAwesomeIcon icon={faSyncAlt} />
      </button>
      <table className="gln-table">
        <thead>
          <tr className="table-header-row">
            <th>Odbiorca</th>
            <th>Płatnik</th>
            <th>GLN</th>
            <th>Automat</th>
            <th>Opis</th>
            <th>NIP</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.length > 0 ? (
            currentRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.ODBIORCA}</td>
                <td>{record.PLATNIK}</td>
                <td>{record.GLN}</td>
                <td>{record.AUTOMAT === "X" ? "Tak" : "Nie"}</td>
                <td>{record.OPIS}</td>
                <td>{record.NIP}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Brak wyników spełniających kryteria.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Nawigacja do stronicowania */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Poprzednia strona
        </button>
        <span>Strona {currentPage}</span>
        <button
          onClick={handleNextPage}
          disabled={
            currentPage === Math.ceil(filteredRecords.length / recordsPerPage)
          }
        >
          Następna strona
        </button>
      </div>
    </div>
  );
};

export default GlnTable;
