import React, { useState, useEffect } from "react";
import Login from "./Login";
import OrdersTable from "./Lista";
import GlnTable from "./Gln"; // Import komponentu do wyświetlania danych GLN
import Menu from "./Menu"; // Import nowego komponentu Menu
import ListaAkc from "./ListaAkc"; // Import nowego komponentu ListaAkc
import "./App.css"; // Import stylów globalnych
import Swal from "sweetalert2";
import ChangePassword from "./ChangePassword";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState("orders"); // Domyślna strona to lista zamówień
  const [userData, setUserData] = useState(null); // Przechowywanie danych użytkownika

  // Funkcja obsługująca zalogowanie
  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true"); // Zapisujemy stan logowania w LocalStorage

    // Pobieranie danych użytkownika z localStorage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (storedUserData) {
      setUserData(storedUserData); // Aktualizacja danych użytkownika w stanie
    }
  };

  // Funkcja obsługująca wylogowanie
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn"); // Usuwamy stan logowania z LocalStorage
    localStorage.removeItem("userData"); // Usuwamy dane użytkownika
    setUserData(null); // Resetowanie stanu użytkownika
  };

  const handleChengePassword = (userData) => {
    <ChangePassword
      userData={userData}
      onClose={() => setCurrentPage("akcept")}
    />;
    Swal.fire({
      title: "Sukces!",
      text: "Hasło zostało zmienione.",
      icon: "success",
      timer: 3000,
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  // Sprawdzanie, czy użytkownik jest zalogowany przy pierwszym załadowaniu
  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === "true") {
      setIsLoggedIn(true); // Jeśli użytkownik był zalogowany, ustawiamy stan na true

      // Pobieranie danych użytkownika z localStorage
      const storedUserData = JSON.parse(localStorage.getItem("userData"));
      if (storedUserData) {
        setUserData(storedUserData); // Aktualizacja danych użytkownika w stanie
      }
    }
  }, []);

  // Renderowanie strony w zależności od wybranej opcji
  const renderPage = () => {
    if (currentPage === "orders") {
      // Wyświetl listę zamówień
      return (
        <>
          <OrdersTable /> {/* Komponent sam ładuje swoje dane */}
        </>
      );
    } else if (currentPage === "akcept") {
      // Wyświetl listę akceptacji
      return (
        <>
          <ListaAkc userData={userData} />{" "}
          {/* Komponent sam ładuje swoje dane */}
        </>
      );
    } else if (currentPage === "nico") {
      // Wyświetl listę akceptacji
      return <></>;
    } else if (currentPage === "gln") {
      // Wyświetl listę GLN
      return (
        <>
          <GlnTable /> {/* Komponent sam ładuje swoje dane */}
        </>
      );
    } else if (currentPage === "listaAkc") {
      // Wyświetl komponent ListaAkc
      return <ListaAkc />;
    }
  };

  // Jeśli użytkownik nie jest zalogowany, wyświetlamy komponent Login
  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  // Wyświetlamy menu oraz stronę zależnie od aktualnie wybranej opcji
  return (
    <div className="app-container">
      <Menu
        setCurrentPage={setCurrentPage}
        handleLogout={handleLogout}
        handleChengePassword={handleChengePassword}
        userData={userData}
      />
      <div className="content">{renderPage()}</div> {/* Treść strony */}
    </div>
  );
}

export default App;
