// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gln-container {
  padding: 20px;
}

.gln-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.gln-table th,
.gln-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.gln-table th {
  background-color: #3f51b5;
  color: white;
}

.table-header-row {
  background-color: #3f51b5;
  color: white;
}

.gln-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.gln-table tr:hover {
  background-color: #ddd;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #303f9f;
}

.pagination span {
  font-size: 16px;
  font-weight: bold;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.filter-container input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.filter-container input:focus {
  border-color: #3f51b5;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/Gln.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".gln-container {\n  padding: 20px;\n}\n\n.gln-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 20px;\n}\n\n.gln-table th,\n.gln-table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\n.gln-table th {\n  background-color: #3f51b5;\n  color: white;\n}\n\n.table-header-row {\n  background-color: #3f51b5;\n  color: white;\n}\n\n.gln-table tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.gln-table tr:hover {\n  background-color: #ddd;\n}\n\n.pagination {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.pagination button {\n  background-color: #3f51b5;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n}\n\n.pagination button:hover {\n  background-color: #303f9f;\n}\n\n.pagination span {\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.filter-container {\n  margin-bottom: 20px;\n  display: flex;\n  gap: 10px;\n}\n\n.filter-container input {\n  padding: 8px;\n  font-size: 14px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 200px;\n}\n\n.filter-container input:focus {\n  border-color: #3f51b5;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
