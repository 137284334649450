import React, { useState } from "react";
import "./Login.css"; // Załaduj plik CSS do stylizacji
import Swal from "sweetalert2";
function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [forceChangePassword, setForceChangePassword] = useState(false); // Flaga wymuszenia zmiany hasła
  const [userData, setUserData] = useState(null); // Przechowywanie danych użytkownika

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sprawdzanie użytkownika testowego
    try {
      // Wysyłanie zapytania do endpointu /getUser
      const response = await fetch(
        `/getUser?user=${encodeURIComponent(
          username
        )}&password=${encodeURIComponent(password)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Sprawdzenie, czy odpowiedź ma status 200
      if (response.ok) {
        const data = await response.json();

        // Sprawdzenie, czy hasło w odpowiedzi zgadza się z podanym
        if (data.USER && data.USER.PASSWORD === password) {
          // Zapisz dane użytkownika w localStorage
          localStorage.setItem(
            "userData",
            JSON.stringify({
              username: data.USER.USERNAME,
              imie: data.USER.IMIE,
              nazwisko: data.USER.NAZWISKO,
              stanowisko: data.USER.STANOWISKO,
              akcept: data.USER.AKCEPT,
            })
          );

          // Sprawdzenie, czy pole INIC jest równe "X", co oznacza konieczność zmiany hasła
          if (data.USER.INIC === "X") {
            setForceChangePassword(true); // Ustawienie flagi do zmiany hasła
            setUserData(data.USER); // Zachowanie danych użytkownika
          } else {
            onLogin(); // Logowanie udane, przekierowanie użytkownika
          }
        } else {
          setError("Nieprawidłowe hasło.");
        }
      } else {
        setError("Nieprawidłowy użytkownik lub hasło.");
      }
    } catch (error) {
      console.error("Błąd podczas logowania:", error);
      setError("Wystąpił błąd podczas próby logowania.");
    }
  };

  const handlePasswordChangeSubmit = async (e) => {
    e.preventDefault();

    // Sprawdzenie, czy oba nowe hasła są takie same
    if (newPassword !== confirmNewPassword) {
      setError("Hasła muszą być jednakowe.");
      return;
    }

    try {
      // Wysyłanie zapytania POST do /updateUser
      const response = await fetch("/updateUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          USERNAME: userData.USERNAME,
          PASSWORD: password, // Stare hasło
          NEW_PASSWORD: newPassword, // Nowe hasło
        }),
      });

      if (response.ok) {
        // Jeśli zmiana hasła zakończyła się sukcesem, zaloguj użytkownika
        Swal.fire({
          title: "Sukces!",
          text: "Hasło zostało zmienione.",
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        onLogin();
      } else {
        setError("Wystąpił błąd podczas zmiany hasła.");
      }
    } catch (error) {
      console.error("Błąd podczas zmiany hasła:", error);
      setError("Wystąpił błąd podczas zmiany hasła.");
    }
  };

  // Renderowanie formularza zmiany hasła
  if (forceChangePassword) {
    return (
      <div className="login-container">
        <div className="login-box">
          <h1 className="login-title">Zmień hasło</h1>
          <form onSubmit={handlePasswordChangeSubmit}>
            <div className="input-group">
              <label>Nowe hasło:</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="login-input"
                required
              />
            </div>
            <div className="input-group">
              <label>Potwierdź nowe hasło:</label>
              <input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="login-input"
                required
              />
            </div>
            <button type="submit" className="login-button">
              Zmień hasło
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    );
  }

  // Renderowanie standardowego formularza logowania
  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="login-title">Portal Marcopol Majster</h1>
        <h1 className="login-title">Zaloguj się</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Login:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-group">
            <label>Hasło:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <button type="submit" className="login-button">
            Zaloguj
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default Login;
