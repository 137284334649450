import React from "react";

import "./Menu.css"; // Import stylów dla menu
import ChangePassword from "./ChangePassword";
const Menu = ({
  setCurrentPage,
  handleLogout,
  handleChengePassword,
  userData,
  faPowerOff,
}) => {
  return (
    <nav className="menu">
      <div className="menu-items">
        <ul>
          <li onClick={() => setCurrentPage("akcept")}>
            Zamówienia do akceptacji
          </li>
          <li onClick={() => setCurrentPage("orders")}>Lista Zamówień</li>
          <li onClick={() => setCurrentPage("gln")}>Lista GLN</li>{" "}
          {/* Nowa pozycja menu */}
        </ul>
      </div>
      {/* Wyloguj oraz dane użytkownika */}
      <div className="user-info">
        <ChangePassword
          onClose={() => setCurrentPage("akcept")}
          logout={handleLogout}
          userData={userData}
        />
      </div>
    </nav>
  );
};

export default Menu;
