import React, { useState, useEffect } from "react";
import "./ListaAkc.css"; // Nowy plik CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader"; // Import loadera
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import {
  faClipboardList,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Funkcja do formatowania wartości liczbowych
const formatNumber = (value, decimals) => {
  if (!value && value !== 0) return "-";
  return value
    .toFixed(decimals)
    .replace(/\d(?=(\d{3})+\.)/g, "$& ")
    .replace(".", ",");
};

// Funkcja do zarządzania rozwinięciem pozycji
const handleToggle = (index, expandedIndex, setExpandedIndex) => {
  setExpandedIndex(expandedIndex === index ? null : index);
};

// Główny komponent OrdersTable
const OrdersTable = ({ userData }) => {
  const [expandedPos, setExpandedPos] = useState(null);
  const [data, setData] = useState(null); // Użycie stanu do zarządzania danymi zamówień
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 50;
  const [loading, setLoading] = useState(true); // Dodanie stanu do zarządzania ładowaniem

  // Stan do przechowywania wartości w polach wyszukiwania
  const [searchQuery, setSearchQuery] = useState({
    odbiorcaNr: "",
    odbiorcaNazwa: "",
    miasto: "",
    ulica: "",
    numerZamowienia: "",
    data: "",
    wartoscNetto: "",
  });

  // Funkcja do pobrania danych zamówień
  const handleRefresh = () => {
    fetchOrders(); // Wywołanie ponownego pobrania danych
  };

  const fetchOrders = async () => {
    try {
      setLoading(true); // Ustawienie stanu ładowania na true przed rozpoczęciem pobierania
      const response = await fetch("/getDataOrders?akceptacja=X");
      if (!response.ok) {
        throw new Error(`Błąd HTTP: ${response.status}`);
      }
      const result = await response.json();
      setData(result.ORDERS_ECM); // Aktualizacja stanu z nowymi danymi
      setLoading(false); // Ustawienie stanu ładowania na false po zakończeniu pobierania
    } catch (error) {
      console.error("Błąd podczas pobierania danych zamówień:", error);
      setLoading(false); // Nawet w przypadku błędu ustawiamy ładowanie na false
    }
  };

  // Wywołanie pobierania danych przy pierwszym renderze komponentu
  useEffect(() => {
    fetchOrders();
  }, []);

  // Funkcja do obsługi zmiany wyszukiwania
  const handleSearchChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };

  const handleMajsterAction = (order, cooo) => {
    const akceptacja = cooo; // Stała wartość dla akceptacji
    const userData = localStorage.getItem("userData"); // Pobieramy dane użytkownika z localStorage
    const vbeln = order.VBELN; // Numer zamówienia

    if (!userData) {
      Swal.fire({
        icon: "error",
        title: "Błąd",
        text: "Nie można znaleźć danych użytkownika.",
      });
      return;
    }
    let info = cooo === "X" ? "zaakceptować" : "odrzucić";
    let info2 = cooo === "X" ? "zaakceptowane" : "odrzucone";
    let info3 = cooo === "X" ? "zaakceptuj" : "odrzuć";
    const user = JSON.parse(userData).username; // Pobieramy nazwę użytkownika z zapamiętanych danych

    // Wyświetlenie bardziej estetycznego potwierdzenia
    Swal.fire({
      title: `Czy na pewno chcesz ${info} zamówienie ${vbeln}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Tak, ${info3}`,
      cancelButtonText: "Anuluj",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Wysyłanie akceptacji dla zamówienia: ", vbeln);

        // Wywołanie API POST do serwera
        fetch(`/sap/zrest_exm/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            vbeln: vbeln,
            akceptacja: akceptacja,
            user: user,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          })
          .then((data) => {
            console.log("Odpowiedź z serwera:", data);
            Swal.fire({
              title: `Zamówienie ${vbeln} zostało ${info2}!`,
              icon: "success",
            });
            fetchOrders(); // Odświeżenie danych po sukcesie
          })
          .catch((error) => {
            console.error(`Błąd podczas wysyłania ${info} `, error);
            Swal.fire({
              icon: "error",
              title: "Błąd",
              text: `Nie udało się ${info} zamówienia ${vbeln}. Błąd: ${error.message}`,
            });
          });
      }
    });
  };
  // Filtrowanie na podstawie wpisanych wartości w polach wyszukiwania
  const filteredOrders = data
    ? data.TAB_ORDERS.filter(
        (order) =>
          order.KUNNR_ODB.toLowerCase().includes(
            searchQuery.odbiorcaNr.toLowerCase()
          ) &&
          (order.NAME_ODB1 + " " + order.NAME_ODB2)
            .toLowerCase()
            .includes(searchQuery.odbiorcaNazwa.toLowerCase()) &&
          order.CITY.toLowerCase().includes(searchQuery.miasto.toLowerCase()) &&
          order.STREET.toLowerCase().includes(
            searchQuery.ulica.toLowerCase()
          ) &&
          order.VBELN.includes(searchQuery.numerZamowienia) &&
          order.ERDAT.includes(searchQuery.data) &&
          formatNumber(order.NETWR, 3).includes(searchQuery.wartoscNetto)
      )
    : [];

  // Stronicowanie
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  if (loading) {
    return (
      <div className="loader-container">
        <h1>Trwa ładowanie danych zamówień</h1>
        <ClipLoader color="black" loading={loading} size={50} />
      </div>
    );
  }

  // Zabezpieczamy przed przypadkiem, gdy dane nie zawierają klucza TAB_ORDERS
  if (!data || !data.TAB_ORDERS) {
    return <p>Brak danych do wyświetlenia</p>;
  }

  // Zmiana strony
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredOrders.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Funkcja do renderowania pozycji (TAB_POS)
  const renderTabPos = (order, expandedPos, index) => {
    if (expandedPos !== index) return null;

    return (
      <tr>
        <td colSpan="7">
          <table border="0">
            <thead class="poz">
              <tr>
                <th className="glowa">Pozycja</th>
                <th className="glowa">Materiał</th>
                <th className="glowa">Opakowanie</th>
                <th className="glowa">Nazwa</th>
                <th className="glowa">Jm</th>
                <th class="glowa prawa">Ilość</th>
                <th class="glowa prawa">Wartość netto</th>
              </tr>
            </thead>
            <tbody>
              {order.TAB_POS.map((pos) => (
                <tr key={pos.POSNR}>
                  <td>{pos.POSNR}</td>
                  <td>{pos.MATNR}</td>
                  <td>{pos.CHARG.substring(0, 3)}</td>
                  <td>{pos.ARKTX}</td>
                  <td>{pos.MEINS}</td>
                  <td class="numeric">{formatNumber(pos.KWMENG, 3)}</td>
                  <td class="numeric">{formatNumber(pos.NETWR, 2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  return (
    <div className="data-container2">
      <button className="refresh-button" onClick={handleRefresh}>
        <FontAwesomeIcon icon={faSyncAlt} /> Zamówienia do akceptacji
      </button>

      {/* Pola wyszukiwania */}
      <div className="filter-container">
        <input
          type="text"
          placeholder="Odbiorca nr"
          name="odbiorcaNr"
          value={searchQuery.odbiorcaNr}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Odbiorca nazwa"
          name="odbiorcaNazwa"
          value={searchQuery.odbiorcaNazwa}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Miasto"
          name="miasto"
          value={searchQuery.miasto}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Ulica"
          name="ulica"
          value={searchQuery.ulica}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Numer Zamówienia"
          name="numerZamowienia"
          value={searchQuery.numerZamowienia}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Data"
          name="data"
          value={searchQuery.data}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Wartość Netto"
          name="wartoscNetto"
          value={searchQuery.wartoscNetto}
          onChange={handleSearchChange}
        />
      </div>

      <table>
        <thead>
          <tr className="table-header-row">
            <th>Numer Zamówienia</th>
            <th>Odbiorca nr</th>
            <th>Odbiorca nazwa</th>
            <th>Miasto</th>
            <th>Ulica</th>
            <th>Data</th>
            <th class="prawa">Wartość Netto</th>
            <th>Waluta</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((order, index) => (
            <React.Fragment key={order.VBELN}>
              <tr>
                <td>{order.VBELN}</td>
                <td>{order.KUNNR_ODB}</td>
                <td>
                  {order.NAME_ODB1} {order.NAME_ODB2}
                </td>
                <td>{order.CITY}</td>
                <td>{order.STREET}</td>

                <td>{order.ERDAT}</td>
                <td class="numeric">{formatNumber(order.NETWR, 3)}</td>
                <td>{order.WAERK}</td>
                <td>
                  <button
                    onClick={() =>
                      handleToggle(index, expandedPos, setExpandedPos)
                    }
                  >
                    <FontAwesomeIcon icon={faClipboardList} title="Pozycje" />
                  </button>
                  {userData.akcept === "X" && (
                    <button
                      class="button2"
                      onClick={() => handleMajsterAction(order, "X")}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "green", backgroundColor: "white" }}
                        title="Zatwierdzenia"
                      />
                    </button>
                  )}
                  {userData.akcept === "X" && (
                    <button
                      class="button2"
                      onClick={() => handleMajsterAction(order, "N")}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{ color: "red", backgroundColor: "white" }}
                        title="Odrzucenie"
                      />
                    </button>
                  )}
                </td>
              </tr>

              {renderTabPos(order, expandedPos, index)}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Nawigacja do stronicowania */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Poprzednia strona
        </button>
        <span>Strona {currentPage}</span>
        <button
          onClick={handleNextPage}
          disabled={
            currentPage === Math.ceil(filteredOrders.length / ordersPerPage)
          }
        >
          Następna strona
        </button>
      </div>
    </div>
  );
};

export default OrdersTable;
