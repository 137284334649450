import React, { useState, useEffect } from "react";
import "./Lista.css"; // Import nowego pliku CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import {
  faClipboardList,
  faFileInvoice,
  faWarehouse,
  faCheckCircle,
  faDownload,
  faTimesCircle,
  faQuestionCircle,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader"; // Import loadera

// Funkcja do pobierania PDF
const downloadPdf = (pdfBase64, fileName) => {
  const link = document.createElement("a");
  link.href = `data:application/pdf;base64,${pdfBase64}`;
  link.download = fileName;
  link.click();
};

// Funkcja do zarządzania rozwinięciem pozycje
const handleToggle = (index, expandedIndex, setExpandedIndex) => {
  setExpandedIndex(expandedIndex === index ? null : index);
};

// Funkcja do formatowania wartości liczbowych
const formatNumber = (value, decimals) => {
  if (!value && value !== 0) return "-";
  return value
    .toFixed(decimals)
    .replace(/\d(?=(\d{3})+\.)/g, "$& ")
    .replace(".", ",");
};

// Funkcja do renderowania pozycji (TAB_POS)
const renderTabPos = (order, expandedPos, index) => {
  if (expandedPos !== index) return null;

  return (
    <tr>
      <td colSpan="5">
        <table border="0">
          <thead>
            <tr>
              <th className="glowa">Pozycja</th>
              <th className="glowa">Materiał</th>
              <th className="glowa">Opakowanie</th>
              <th className="glowa">Nazwa</th>
              <th className="glowa">Jm</th>
              <th className="glowa prawa">Ilość</th>
              <th className="glowa prawa">Wartość netto</th>
            </tr>
          </thead>
          <tbody>
            {order.TAB_POS.map((pos) => (
              <tr key={pos.POSNR}>
                <td>{pos.POSNR}</td>
                <td>{pos.MATNR}</td>
                <td>{pos.CHARG.substring(0, 3)}</td>
                <td>{pos.ARKTX}</td>
                <td>{pos.MEINS}</td>
                <td className="numeric">{formatNumber(pos.KWMENG, 3)}</td>
                <td className="numeric">{formatNumber(pos.NETWR, 2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

// Funkcja do renderowania WZ (TAB_WZ)
const renderTabWz = (data, order, expandedWz, index) => {
  if (expandedWz !== index) return null;

  return (
    <tr>
      <td colSpan="5">
        <table border="0">
          <thead>
            <tr>
              <th className="glowa">Numer WZ</th>
              <th className="glowa">Data</th>
              <th className="glowa">Nr listu</th>
              <th className="glowa">Tracking</th>{" "}
            </tr>
          </thead>
          <tbody>
            {data.TAB_WZ.filter((wz) => wz.WZ === order.TAB_POS[0].WZ).map(
              (wz) => (
                <tr key={wz.WZ}>
                  <td>{wz.WZ}</td>
                  <td>{wz.DATA}</td>
                  <td>{wz.NR_LISTU}</td>
                  <td>
                    <a
                      href={wz.LINKLISTU}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {wz.LINKLISTU ? "Śledź przesyłkę" : "Brak linku"}
                    </a>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

// Funkcja do renderowania faktur (TAB_FA)
const renderTabFa = (data, order, expandedFa, index) => {
  if (expandedFa !== index) return null;

  return (
    <tr>
      <td colSpan="5">
        <table border="0">
          <thead>
            <tr>
              <th className="glowa">Numer FA</th>
              <th className="glowa">Data</th>
              <th className="glowa prawa">Wartość Netto</th>
              <th className="glowa">Plik Pdf</th>
            </tr>
          </thead>
          <tbody>
            {data.TAB_FA.filter((fa) => fa.FA === order.TAB_POS[0].FA).map(
              (fa) => (
                <tr key={fa.FA}>
                  <td>{fa.FA}</td>
                  <td>{fa.DATA}</td>
                  <td className="numeric">{formatNumber(fa.NETWR, 2)}</td>
                  <td>
                    <button
                      onClick={() => downloadPdf(fa.PDF_BASE64, fa.PLIKI)}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        title="Pobierz fakturę"
                      />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

// Funkcja do renderowania majstra (TAB_MAJSTER)
const renderTabMajster = (data, order, expandedMajster, index) => {
  if (expandedMajster !== index) return null;

  return (
    <tr>
      <td colSpan="5">
        <table border="0">
          <thead>
            <tr>
              <th className="glowa">GLN</th>
              <th className="glowa">Akceptacja</th>
              <th className="glowa">Akceptacja osoba</th>
              <th className="glowa">Akceptacja Data</th>
              <th className="glowa">Akceptacja Czas</th>
            </tr>
          </thead>
          <tbody>
            {data.TAB_MAJSTER.filter(
              (majster) => majster.VBELN === order.VBELN
            ).map((majster) => (
              <tr key={majster.VBELN}>
                <td>{majster.GLN}</td>

                <td>
                  {majster.AKCEPT === "X" ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                      title={`Zatwierdzona `}
                    />
                  ) : majster.AKCEPT === "Z" ? (
                    <FontAwesomeIcon
                      icon={faClipboardCheck}
                      style={{ color: "blue" }}
                      title={`Zakończona`}
                    />
                  ) : majster.AKCEPT === "N" ? (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ color: "red" }}
                      title={`Odrzucona`}
                    />
                  ) : majster.AKCEPT === "O" ? (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ color: "red" }}
                      title={`Zrealizowane odrzucenie`}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      style={{ color: "orange" }}
                      title={`Oczekuje na decyzję`}
                    />
                  )}
                </td>
                <td>{majster.AKCEPT_UWEB}</td>
                <td>{majster.AKCEPT_DATA}</td>
                <td>{majster.AKCEPT_CZAS}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

// Główny komponent OrdersTable
const OrdersTable = () => {
  const [expandedPos, setExpandedPos] = useState(null);
  const [expandedWz, setExpandedWz] = useState(null);
  const [expandedFa, setExpandedFa] = useState(null);
  const [expandedMajster, setExpandedMajster] = useState(null);

  const [data, setData] = useState(null); // Stan do przechowywania danych
  const [loading, setLoading] = useState(true); // Stan do zarządzania ładowaniem
  const [currentPage, setCurrentPage] = useState(1); // Stronicowanie
  const [searchQuery, setSearchQuery] = useState({
    odbiorcaNr: "",
    odbiorcaNazwa: "",
    miasto: "",
    ulica: "",
    numerZamowienia: "",
    data: "",
    wartoscNetto: "",
  });

  const ordersPerPage = 50;

  const handleRefresh = () => {
    fetchOrders(); // Wywołanie ponownego pobrania danych
  };

  // Funkcja do pobrania danych zamówień
  const fetchOrders = async () => {
    try {
      setLoading(true); // Ustawienie ładowania na true przed pobraniem danych
      const response = await fetch("/getDataOrders?akceptacja=N");
      if (!response.ok) {
        throw new Error(`Błąd HTTP: ${response.status}`);
      }
      const result = await response.json();
      setData(result.ORDERS_ECM); // Aktualizacja danych zamówień
      setLoading(false); // Ustawienie ładowania na false po zakończeniu pobierania
    } catch (error) {
      console.error("Błąd podczas pobierania danych zamówień:", error);
      setLoading(false); // Nawet w przypadku błędu wyłączamy stan ładowania
    }
  };

  // Pobieranie danych przy pierwszym renderze
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };

  // Filtrowanie na podstawie wpisanych wartości w polach wyszukiwania
  const filteredOrders = data
    ? data.TAB_ORDERS.filter(
        (order) =>
          order.KUNNR_ODB.toLowerCase().includes(
            searchQuery.odbiorcaNr.toLowerCase()
          ) &&
          (order.NAME_ODB1 + " " + order.NAME_ODB2)
            .toLowerCase()
            .includes(searchQuery.odbiorcaNazwa.toLowerCase()) &&
          order.CITY.toLowerCase().includes(searchQuery.miasto.toLowerCase()) &&
          order.STREET.toLowerCase().includes(
            searchQuery.ulica.toLowerCase()
          ) &&
          order.VBELN.includes(searchQuery.numerZamowienia) &&
          order.ERDAT.includes(searchQuery.data) &&
          formatNumber(order.NETWR, 2).includes(searchQuery.wartoscNetto)
      )
    : [];

  // Stronicowanie
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  if (loading) {
    return (
      <div className="loader-container">
        <h1>Trwa ładowanie danych zamówień</h1>
        <ClipLoader color="black" loading={loading} size={50} />
      </div>
    );
  }

  if (!data || !data.TAB_ORDERS) {
    return <p>Brak danych do wyświetlenia</p>;
  }

  // Zmiana strony
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredOrders.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="data-container">
      <button className="refresh-button" onClick={handleRefresh}>
        <FontAwesomeIcon icon={faSyncAlt} /> Zamówienia po akceptacji
      </button>

      {/* Pola wyszukiwania */}
      <div className="filter-container">
        <input
          type="text"
          placeholder="Odbiorca nr"
          name="odbiorcaNr"
          value={searchQuery.odbiorcaNr}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Odbiorca nazwa"
          name="odbiorcaNazwa"
          value={searchQuery.odbiorcaNazwa}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Miasto"
          name="miasto"
          value={searchQuery.miasto}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Ulica"
          name="ulica"
          value={searchQuery.ulica}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Numer Zamówienia"
          name="numerZamowienia"
          value={searchQuery.numerZamowienia}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Data"
          name="data"
          value={searchQuery.data}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Wartość Netto"
          name="wartoscNetto"
          value={searchQuery.wartoscNetto}
          onChange={handleSearchChange}
        />
      </div>

      <table>
        <thead>
          <tr className="table-header-row">
            <th>Numer Zamówienia</th>
            <th>Odbiorca nr</th>
            <th>Odbiorca nazwa</th>
            <th>Miasto</th>
            <th>Ulica</th>
            <th>Data</th>
            <th className="prawa">Wartość Netto</th>
            <th>Waluta</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((order, index) => (
            <React.Fragment key={order.VBELN}>
              <tr>
                <td>{order.VBELN}</td>
                <td> {order.KUNNR_ODB} </td>
                <td>
                  {order.NAME_ODB1} {order.NAME_ODB2}
                </td>
                <td>{order.CITY}</td>
                <td>{order.STREET}</td>

                <td>{order.ERDAT}</td>
                <td className="numeric">{formatNumber(order.NETWR, 2)}</td>
                <td>{order.WAERK}</td>
                <td>
                  <button
                    onClick={() =>
                      handleToggle(index, expandedPos, setExpandedPos)
                    }
                  >
                    <FontAwesomeIcon icon={faClipboardList} title="Pozycje" />
                  </button>

                  {order.WZ === "X" ? (
                    <button
                      onClick={() =>
                        handleToggle(index, expandedWz, setExpandedWz)
                      }
                    >
                      <FontAwesomeIcon icon={faWarehouse} title="Wz" />
                    </button>
                  ) : (
                    ""
                  )}

                  {order.FA === "X" ? (
                    <button
                      onClick={() =>
                        handleToggle(index, expandedFa, setExpandedFa)
                      }
                    >
                      <FontAwesomeIcon icon={faFileInvoice} title="Faktura" />
                    </button>
                  ) : (
                    ""
                  )}
                  {order.PO === "X" ? (
                    <button
                      className="custom-button"
                      onClick={() =>
                        handleToggle(index, expandedMajster, setExpandedMajster)
                      }
                    >
                      {order.AKCEPT === "X" ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ color: "green" }}
                          title={`Zatwierdzona `}
                        />
                      ) : order.AKCEPT === "Z" ? (
                        <FontAwesomeIcon
                          icon={faClipboardCheck}
                          style={{ color: "blue" }}
                          title={`Zakończona`}
                        />
                      ) : order.AKCEPT === "N" ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ color: "red" }}
                          title={`Odrzucona`}
                        />
                      ) : order.AKCEPT === "O" ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ color: "red" }}
                          title={`Zrealizowane odrzucenie`}
                        />
                      ) : order.AKCEPT === "P" ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ color: "orange" }}
                          title={`W przygotowaniu`}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          style={{ color: "orange" }}
                          title={`Oczekuje na decyzję`}
                        />
                      )}
                    </button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              {/* Renderowanie rozwiniętych wierszy */}
              {expandedPos === index && renderTabPos(order, expandedPos, index)}
              {expandedWz === index &&
                renderTabWz(data, order, expandedWz, index)}
              {expandedFa === index &&
                renderTabFa(data, order, expandedFa, index)}
              {expandedMajster === index &&
                renderTabMajster(data, order, expandedMajster, index)}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Nawigacja do stronicowania */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Poprzednia strona
        </button>
        <span>Strona {currentPage}</span>
        <button
          onClick={handleNextPage}
          disabled={
            currentPage === Math.ceil(filteredOrders.length / ordersPerPage)
          }
        >
          Następna strona
        </button>
      </div>
    </div>
  );
};

export default OrdersTable;
