import React from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import {
  faKey,
  faPowerOff,
  faEdit,
  faLock,
} from "@fortawesome/free-solid-svg-icons"; // Import ikon
function ChangePassword({ onClose, userData, logout }) {
  const handlePasswordChangeSubmit = async (
    oldPassword,
    newPassword,
    uname
  ) => {
    try {
      const response = await fetch(`/updateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          USERNAME: uname,
          PASSWORD: oldPassword,
          NEW_PASSWORD: newPassword,
        }),
      });

      if (response.ok) {
        Swal.fire({
          title: "Sukces!",
          text: "Hasło zostało zmienione.",
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        });

        setTimeout(onClose, 3000);
      } else {
        Swal.fire({
          title: "Błąd!",
          text: "Wystąpił błąd podczas zmiany hasła.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Błąd podczas zmiany hasła:", error);
      Swal.fire({
        title: "Błąd!",
        text: "Wystąpił błąd podczas zmiany hasła.",
        icon: "error",
      });
    }
  };

  const showModal = () => {
    Swal.fire({
      title: `Zmień hasło `,
      html: `
        <input id="user2" class="swal2-input" placeholder="Użytkownik" >
        <input id="oldPassword" class="swal2-input" placeholder="Stare hasło" type="password">
        <input id="newPassword" class="swal2-input" placeholder="Nowe hasło" type="password">
        <input id="confirmNewPassword" class="swal2-input" placeholder="Potwierdź nowe hasło" type="password">
      `,
      focusConfirm: false,
      preConfirm: () => {
        const oldPassword = document.getElementById("oldPassword").value;
        const newPassword = document.getElementById("newPassword").value;
        const user2 = document.getElementById("user2").value;
        const confirmNewPassword =
          document.getElementById("confirmNewPassword").value;

        if (newPassword !== confirmNewPassword) {
          Swal.showValidationMessage("Hasła muszą być jednakowe.");
          return false;
        }

        handlePasswordChangeSubmit(oldPassword, newPassword, user2);
      },
      showCancelButton: true,
    });
  };

  return (
    <div>
      <div>
        <ul style={{ listStyleType: "none", padding: 0, display: "flex" }}>
          <li
            onClick={showModal}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faKey} title="Zmień hasło" />
          </li>
          <li onClick={logout} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPowerOff} title="Wyloguj" />
          </li>
        </ul>
      </div>
      {userData && (
        <div className="user-details">
          <p>
            {userData.imie} {userData.nazwisko}
          </p>
          <p>
            {userData.stanowisko}{" "}
            {userData.akcept === "X" ? (
              <FontAwesomeIcon icon={faEdit} title="Edycja dostępna" />
            ) : (
              <FontAwesomeIcon icon={faLock} title="Edycja zablokowana" />
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
